import { loader } from "@monaco-editor/react";
/**
 * This script defines the Wittongue language in the Monaco editor, sets up the tokenization rules and syntax highlighting,
 * registers the language with the editor, and finally returns the monaco instance.
 * @returns the monaco instance to be used for the editor.
 */
export async function defineWittongue() {
  try {
    const monaco = await loader.init();

    // Define the new language
    monaco.languages.register({ id: "wittongue" });

    // Define the language's configuration
    monaco.languages.setLanguageConfiguration("wittongue", {
      // This will insert a space after typing a comma
      autoClosingPairs: [
        { open: "{", close: "}" },
        { open: "[", close: "]" },
        { open: "(", close: ")" },

        { open: "'", close: "'" },
        { open: '"', close: '"' },
        { open: "`", close: "`" },
        { open: "<>", close: "</>" },
        { open: "/**", close: " */", notIn: ["string"] },
        { open: "/*", close: " */", notIn: ["string", "comment"] },
        { open: "//", close: "", notIn: ["string", "comment"] },
      ],

      indentationRules: {
        increaseIndentPattern:
          /(?:[=]+part\s+[a-z_]*)|(?:^\s*[+*-]+[a-zA-Z0-9!?'\s.,:]+$)/gm,
        decreaseIndentPattern: /^\s*[=]+end/gm,
      },
      // This will auto close brackets and quotes when typing them
      brackets: [
        ["`", "`"],
        ["{", "}"],
        ["[", "]"],
        ["(", ")"],
      ],
      comments: {
        lineComment: "//",
        blockComment: ["/*", "*/"],
      },
    });

    //skeleton for code actions
    //TODO to implement this, change the language to "wittongue" in the editor
    monaco.languages.registerCodeActionProvider("", {
      provideCodeActions: function (model, range, context, token) {
        const actions = context.markers.flatMap((marker) => ({
          title: `Fix ${marker.message}`,
          diagnostics: [marker],
          kind: "quickfix",
          edit: {
            edits: [
              {
                resource: model.uri,
                versionId: model.getVersionId(),
                textEdit: {
                  range: range,
                  text: "replaced text",
                },
              },
            ],
          },
          isPreferred: true,
        }));

        return {
          actions: actions,
          dispose: function () {},
        };
      },
    });

    // Define the language's syntax highlighting rules
    monaco.languages.setMonarchTokensProvider("wittongue", {
      // Set defaultToken to invalid to see what you do not tokenize yet
      defaultToken: "invalid",
      brackets: [
        { open: "{", close: "}", token: "delimiter.curly" },
        { open: "(", close: ")", token: "delimiter.round" },
        // { open: "[", close: "]", token: "delimiter.square" },
      ],
      // ['[',']','delimiter.square'],
      // ['(',')','delimiter.parenthesis'],
      // ['<','>','delimiter.angle'] ],
      keywords: ["var"],
      stageDirection: /\$[a-zA-Z_0-9:]+/,
      // a-ctors: /^(?:actor|ACTOR) [a-zA-Z0-9-_]+:$/m,
      // n-arrators: /(?:narrate|NARRATE):/,
      choices: /\s*(?:\++|\*+)\s*/,
      // /\s*(?:\++|\*+)\s*(\{(?:[a-zA-Z0-9. \(\)]|==|<|<=|>|>=|!=)*\})? *[a-zA-Z0-9!?' ,.]+/,
      remainingChoiceTextWithCondition:
        /(\{(?:[a-zA-Z0-9. "()`]|==|<|<=|>|>=|!=)*\})(\s*)([a-zA-Z0-9!?' ,.]+)/gm,
      remainingChoiceTextWithoutCondition: /[\[\]a-zA-Z0-9!?' ,.]+/gm,
      gathers: /[-]+[a-zA-Z_.0-9]+/,

      diverts: /=>[a-zA-Z_.0-9]+/,
      operators: [
        "=",
        ">",
        "<",
        ">=",
        "^=",
        "<=",
        "!=",
        "==",
        "&&",
        "||",
        "+",
        "-",
        "*",
        "/",
        "+=",
        "-=",
        "*=",
        "/=",
      ],
      controls: ["?", ":", "~"],

      // we include these common regular expressions
      symbols: /[=><!~?:&|+\-*\/\^%]+/,
      // C# style strings
      escapes:
        /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

      // The main tokenizer for our language
      tokenizer: {
        root: [
          [
            /([=]+part\s*)([a-zA-Z0-9_]+)/,
            { token: "type.identifier", next: "part", bracket: "@open" },
          ], //["keyword", "type.identifier"]],
          // [/part/, { token: "keyword", next: "part" }],
          //include catching function names of the form 'FUNCTIONNAME()'
          [/[a-zA-Z_]+\(/, { token: "operator" }],

          // identifiers and keywords
          [
            /[a-z_][\w]*/gm,
            {
              cases: {
                "@keywords": "keyword",
                "@default": "white",
              },
            },
          ],
          // whitespace
          { include: "@whitespace" },

          // Match brackets and switch to the 'curlybrace' state
          [
            /{/,
            { token: "delimiter.curly", bracket: "@open", next: "curlybrace" },
          ],

          // numbers
          [/\d*\.\d+([eE][\-+]?\d+)?/, "number.float"],
          [/0[xX][0-9a-fA-F]+/, "number.hex"],
          [/\d+/, "number"],
          [
            /@symbols/,
            {
              cases: {
                "@operators": "operator",
                "@controls": "controls",
                "@default": "",
              },
            },
          ],
          // rules for tags
          [
            /@\s*[a-zA-Z_\$][\w\$]*/,
            {
              token: "annotation.within.brackets",
              // log: "tag: $0",
            },
          ],
          [/[a-zA-Z]+/, "default"],

          // delimiter: after number because of .\d floats
          [/[;,.]/, "delimiter"],

          // strings
          [/"([^"\\]|\\.)*$/, "invalid"], // non-teminated string
          [/"/, { token: "string.quote", bracket: "@open", next: "@string" }],
        ],

        comment: [
          [/[^\/*]+/, "comment"],
          [/\/\*/, "comment", "@push"], // nested comment
          [/\*\//, "comment", "@pop"],
          [/[\/*]/, "comment"],
        ],
        part: [
          [
            /\s*[=]+end$/,
            { token: "type.identifier", bracket: "@close", next: "@pop" },
          ],
          { include: "@whitespace" },
          [
            /(=[=]+part\s*)([a-zA-Z0-9_]+)/,
            { token: "type.identifier", next: "part", bracket: "@open" },
          ],
          [
            /\{/,
            {
              token: "delimiter.curly",
              bracket: "@open",
              next: "curlybrace",
            },
          ],
          [/@choices/, "choices", "@choice"],

          [/@stageDirection/, { token: "stageDirection" }],
          [/@gathers/, { token: "navigates" }],
          [/@diverts/, { token: "navigates" }],
          [/<[a-zA-Z]+\>/, { token: "tag.marker", next: "@texttag" }],
          // @ tags.
          // As an example, we emit a debugging log message on these tokens.
          // Note: message are supressed during the first load -- change some lines to see them.
          [
            /@\s*[a-zA-Z_\$][\w\$]*/,
            { token: "tag", log: "annotation token: $0" },
          ],
          [/[a-zA-Z?!.,:;'\"()0-9]+/, "default"],
        ],
        choice: [
          [
            /{/,
            { token: "delimiter.curly", bracket: "@open", next: "curlybrace" },
          ],
          // [
          //   "@remainingChoiceTextWithCondition",
          //   ["inlinelogic", "white", "choices"],
          //   "@pop",
          // ],
          [/\([a-zA-Z0-9_]+\)/, "type.identifier"],
          ["@remainingChoiceTextWithoutCondition", "choices", "@pop"],
        ],
        string: [
          [
            /\{/,
            {
              token: "delimiter.curly",
              bracket: "@open",
              next: "curlybrace",
            },
          ],
          [/[^\\`\{\}]+/, "string"],
          [/@escapes/, "string.escape"],
          [/\\./, "string.escape.invalid"],
          [/`/, { token: "string.quote", bracket: "@close", next: "@pop" }],
        ],
        texttag: [
          [/[^\\<>]+/, "tag"],
          [/<\/[a-zA-Z]*\>/, { token: "tag.marker", next: "@pop" }],
        ],
        whitespace: [
          [/[ \t\r\n]+/, "white"],
          [/\/\*/, "comment", "@comment"],
          [/\/\/.*$/, "comment"],
        ],
        curlybrace: [
          // Match closing bracket and switch back to the 'root' state
          [/}/, { token: "delimiter.curly", bracket: "@close", next: "@pop" }],

          //the rest should be exactly the same as root MINUS the part syntax
          //include catching function names of the form 'FUNCTIONNAME()'
          [/[a-zA-Z_]+\(/, { token: "operator" }],
          //diverts
          [/@diverts/, { token: "navigates" }],

          // identifiers and keywords
          [
            /[a-z_][\w]*/gm,
            {
              cases: {
                "@keywords": "keyword",
                "@default": "inlinelogic",
              },
            },
          ],
          // whitespace
          { include: "@whitespace" },

          // Match brackets and switch to the 'curlybrace' state
          [
            /{/,
            { token: "delimiter.curly", bracket: "@open", next: "curlybrace" },
          ],
          // numbers
          [/\d*\.\d+([eE][\-+]?\d+)?/, "number.float"],
          [/0[xX][0-9a-fA-F]+/, "number.hex"],
          [/\d+/, "number"],
          [
            /@symbols/,
            {
              cases: {
                "@operators": "operator",
                "@controls": "controls",
                "@default": "",
              },
            },
          ],
          // rules for tags
          [
            /@\s*[a-zA-Z_\$][\w\$]*/,
            {
              token: "annotation.within.brackets",
              // log: "tag: $0",
            },
          ],
          [/[a-zA-Z]+/, "default"],

          // delimiter: after number because of .\d floats
          [/[;,.]/, "delimiter"],

          // strings

          [/`/, { token: "string.quote", bracket: "@open", next: "@string" }],
          //handle normal brackets (for functions etc.)
          [
            /\(/,
            {
              token: "delimiter.parenthesis",
              bracket: "@open",
            },
          ],
        ],
      },
    });
    // const itokenTheme:editor.ITokenThemeRule = {
    //   token: "comment",
    //   foreground: "ffa500",
    //   fontStyle: "italic underline",
    // }
    monaco.editor.defineTheme("myCustomTheme", {
      base: "vs-dark", // can also be vs-dark or hc-black
      inherit: true, // can also be false to completely replace the builtin rules
      rules: [
        {
          token: "comment",
          foreground: "29ab87",
          fontStyle: "italic",
        },
        { token: "string", foreground: "#FFFFFF" },
        //teal 29ab87

        {
          token: "inlinelogic",
          foreground: "e998b1",
        },
        //lighter pink #E998B1
        //darker pink #d33163
        {
          token: "choices",
          //tangerine
          // foreground: "f27f0c",
          foreground: "9fe7f5",
        },
        //golden ffa500
        //red 9d1e15
        // blue 5b8cfd
        { token: "tag", foreground: "CC6666" },
        { token: "tag.marker", fontStyle: "italic" },
        { token: "operator", foreground: "#5b8cfd" },
        { token: "controls", foreground: "#FDD782" },
        {
          token: "invalid",
          foreground: "EE4B2B",
          fontStyle: "underline",
        },
        {
          token: "stageDirection",
          foreground: "29ab87",
          fontStyle: "underline",
        },
        { token: "navigates", foreground: "9fe7f5", fontStyle: "underline" },
      ],
      colors: {
        "editor.foreground": "#FFFFFF",
        "editor.lineHighlightBackground": "#FFFFFF0F",
      },
    });

    console.log("Wittongue defined");
    return monaco;
  } catch (e) {
    console.log("Wittongue failed to define", e);
    return null;
  }
}

import { LinePosition } from "@wittongue/editor";
import { TokenTypes } from "./token-type";
/**
 * Base abstract class for all tokens.
 */
export abstract class Token {
  /**
   * The position of the token in the source file/monaco editor model.
   */
  linePosition: LinePosition;
  /**
   * The type of the token, this is used when deserializing from JSON to a Unity class
   */
  type: TokenTypes;
  /**
   * The key of the token, this is used when trying to reference the token or its value.
   */
  key: string;
  constructor(key: string, type: TokenTypes, linePosition: LinePosition) {
    this.key = key;
    this.type = type;
    this.linePosition = linePosition;
  }
}

import { Token, TokenType } from ".";
import { LinePosition } from "@wittongue/editor";

export class TextToken extends Token {
  value: string;
  constructor(initialContent: string, linePosition: LinePosition) {
    super(
      `text_l${linePosition.startLineNumber}_c${linePosition.startColumnNumber}`,
      TokenType.TEXT,
      linePosition
    );
    this.value = initialContent;
  }
  addTextContent(content: string) {
    this.value += content;
  }
}

import { Token, TokenType, TokenSequence } from ".";
import { LinePosition } from "@wittongue/editor";
export class FunctionCallToken extends Token {
  functionName: string;
  arguments: TokenSequence[];
  /**
   * The current argument being built up. This is only used during parsing/compilation. Once the argument is complete, it's added to the `arguments` array and a new `TokenSequence` is created for the next argument.
   */
  currentArgument: TokenSequence;
  commasSeen: number = 0;
  depth: number;
  constructor(functionName: string, depth: number, linePosition: LinePosition) {
    super(
      `function_${functionName}_l${linePosition.startLineNumber}_c${linePosition.startColumnNumber}`,
      TokenType.FUNCTION,
      linePosition
    );
    this.functionName = functionName;
    this.arguments = [
      new TokenSequence(
        linePosition.startLineNumber,
        linePosition.startColumnNumber + this.functionName.length,
        depth
      ),
    ];
    this.currentArgument = this.arguments[0];
    this.depth = depth;
  }

  addArgument() {
    const newArgumentSequence = new TokenSequence(
      this.linePosition.startLineNumber,
      this.linePosition.startColumnNumber +
        this.currentArgument.linePosition.endColumnNumber +
        1,
      this.depth
    );
    this.arguments.push(newArgumentSequence);

    this.currentArgument = newArgumentSequence;
    return newArgumentSequence;
  }
}

import {
  Box,
  Button,
  GridItem,
  HStack,
  Heading,
  Input,
  Link,
  Select,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import Editor from "@monaco-editor/react";
import { useEffect, useRef, useState } from "react";
import { CollapsibleSection } from "ui/base-components";
import WittongueEditor from "./WittongueEditor";
import HoriztonalCollapse from "./HoriztonalCollapse";

import { CompiledEncounter } from "@wittongue/compiler";
import EncounterPlaySimulator from "./EncounterPlaySimulator";
import CompiledEncounterPreview from "./CompiledEncounterPreview";
export default function EncounterEditor() {
  const { isOpen: previewOpen, onToggle } = useDisclosure({
    defaultIsOpen: true,
  });
  const [downloadUrl, setDownloadUrl] = useState<string>();
  const [compiledEncounter, setCompiledEncounter] =
    useState<CompiledEncounter | null>(null);
  function downloadCompiledEncounter() {
    const finalEnc = { ...compiledEncounter, title, description };
    const json = JSON.stringify(finalEnc, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    setDownloadUrl(href);
  }
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  return (
    <Box fontFamily={"edit"}>
      <HStack //pos="sticky" top={"40px"} zIndex={"sticky"}
      >
        <Button onClick={onToggle}>Show Preview Panel</Button>
        <Link href={downloadUrl} download={`${title.replace(" ", "_")}.json`}>
          <Button onClick={downloadCompiledEncounter}>
            Download Encounter
          </Button>
        </Link>
      </HStack>
      <CollapsibleSection title={"Encounter Info"}>
        <Text>Title</Text>{" "}
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={"Title"}
        />
        <Text>Description</Text>
        <Textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={"Description"}
        />
      </CollapsibleSection>
      <SimpleGrid columns={previewOpen ? 2 : 1} pt={5}>
        <GridItem>
          <WittongueEditor setCompiledEncounter={setCompiledEncounter} />
        </GridItem>
        {previewOpen && compiledEncounter && (
          <GridItem>
            <Heading
              fontFamily={"Luckiest Guy"}
              letterSpacing={2}
              textAlign={"center"}
            >
              Preview
            </Heading>
            <EncounterPreview
              encounter={compiledEncounter}
              title={title}
              description={description}
            />
          </GridItem>
        )}
      </SimpleGrid>
    </Box>
  );
}

interface EncounterPreviewProps {
  encounter: CompiledEncounter;
  title: string;
  description: string;
}
function EncounterPreview({
  encounter,
  title,
  description,
}: EncounterPreviewProps) {
  return (
    <Box>
      <Heading>{title}</Heading>
      <Text>{description}</Text>
      <Tabs>
        <TabList>
          <Tab>View Compiled Parts</Tab>
          <Tab>Simulator</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <CompiledEncounterPreview encounter={encounter} />
          </TabPanel>
          <TabPanel>
            <Text textAlign={"center"}>Disabled for now</Text>
            {/* <EncounterPlaySimulator encounter={encounter} /> */}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

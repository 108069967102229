import {
  EncounterPart,
  CompiledEncounter,
  DeclareVariableToken,
  TokenSequence,
  NumberToken,
  DivertToken,
  ControlFlowToken,
  TextToken,
  VariableToken,
  SequenceReferenceToken,
  StageDirectionToken,
  OperatorToken,
} from "@wittongue/compiler";
import { WittongueEditorMessage } from "@wittongue/editor";
import { MarkerSeverity } from "monaco-editor";
export class CompiledEncounterValidator {
  encounter: CompiledEncounter;
  warnings: WittongueEditorMessage[];
  constructor(encounter: CompiledEncounter) {
    this.encounter = encounter;
    this.warnings = [];
  }
  checkWholeEncounter() {
    this.warnings = [];
    for (let i = 0; i < this.encounter.parts.length; i++) {
      const part = this.encounter.parts[i];
      this.checkEncounterPart(part);
    }
    return this.warnings;
  }

  //check that all of the parts are reachable
  checkEncounterPart(part: EncounterPart) {
    for (let i = 0; i < part.contentSequences.length; i++) {
      const nextSequence = part.contentSequences[i + 1] ?? null;
      const sequence = part.contentSequences[i];
      const outcome = this.checkSequence(sequence);
      if (outcome.hasDivert && i < part.contentSequences.length - 1) {
        this.warnings.push(
          new WittongueEditorMessage(
            `The flow always diverts away before this point. Content on and beyond this line will not be reached.`,
            {
              startLineNumber: nextSequence.linePosition.startLineNumber,
              startColumnNumber: nextSequence.linePosition.startColumnNumber,
              endLineNumber: nextSequence.linePosition.startLineNumber,
              endColumnNumber: 1000,
            },
            MarkerSeverity.Warning
          )
        );
      }
      if (outcome.hasDivert && part.choices.length > 0) {
        this.warnings.push(
          new WittongueEditorMessage(
            `The flow will always divert away before reaching this choice.`,
            part.choices[0].linePosition,
            MarkerSeverity.Warning
          )
        );
      }
    }
    for (let i = 0; i < part.nestedSequences.length; i++) {
      const sequence = part.nestedSequences[i];
      this.checkSequence(sequence);
    }
  }

  checkSequence(sequence: TokenSequence): { hasDivert: boolean } {
    const outcome = { hasDivert: false };
    for (let j = 0; j < sequence.tokens.length; j++) {
      const token = sequence.tokens[j];
      const prevToken = sequence.tokens[j - 1]; // we should generally look at the previous token

      if (token instanceof ControlFlowToken) {
        //the ? token is a check for the variable or value before it
        if (token.key === "?") {
          if (
            prevToken instanceof NumberToken ||
            prevToken instanceof TextToken
          ) {
            const operator = sequence.tokens[j - 2];
            if (!(operator instanceof OperatorToken)) {
              this.warnings.push(
                new WittongueEditorMessage(
                  `Currently using a constant ${prevToken.value}. Control flow token ${token.key} should be preceded by a variable or operator, otherwise the outcome will always be the same.`,
                  token.linePosition,
                  MarkerSeverity.Warning
                )
              );
            }
          }
          //also not variables we care about?
          else if (
            !(prevToken instanceof VariableToken) &&
            !(prevToken instanceof SequenceReferenceToken)
          ) {
            throw new WittongueEditorMessage(
              `Expected a value before a control flow token`,
              token.linePosition,
              MarkerSeverity.Error
            );
          }

          //check the remaining control flow tokens for ':' or '~'
          let hasCases = false;
          sequence.tokens.slice(j + 1).forEach((token) => {
            if (token instanceof ControlFlowToken) {
              if (token.key === ":") {
                hasCases = true;
              }
            }
          });

          if (!hasCases) {
            throw new WittongueEditorMessage(
              `True-or-false token '?' should be followed by a ':'`,
              token.linePosition,
              MarkerSeverity.Error
            );
          }
        } else if (token.key === ":") {
          if (prevToken instanceof ControlFlowToken) {
            throw new WittongueEditorMessage(
              `Need a case for the 'true' condition.`,
              token.linePosition,
              MarkerSeverity.Error
            );
          }

          if (!sequence.tokens[j + 1]) {
            throw new WittongueEditorMessage(
              `Need a case for the 'false' condition. Can be a blank string. (\`\`)`,
              token.linePosition,
              MarkerSeverity.Error
            );
          }
        }
      }
      if (token instanceof DeclareVariableToken) {
        if (
          sequence.tokens[j + 1] &&
          !(sequence.tokens[j + 1] instanceof VariableToken)
        ) {
          throw new WittongueEditorMessage(
            `Expected a variable name following 'var' declaration, but saw ${
              sequence.tokens[j + 1].type
            } instead.`,
            token.linePosition,
            MarkerSeverity.Error
          );
        }
      }
      if (token instanceof DivertToken) {
        if (
          token.divertTo.toLowerCase() !== "end" &&
          token.divertTo.toLowerCase() !== "done" &&
          !this.encounter.parts.find((p) => p.key === token.divertTo)
        ) {
          this.warnings.push(
            new WittongueEditorMessage(
              `Part ${token.divertTo} is not reachable or doesn't exist yet. If it is a nested part, be sure to use the syntax "parent.child"`,
              token.linePosition,
              MarkerSeverity.Warning
            )
          );
        }
        outcome.hasDivert = true;
      }
      if (token instanceof OperatorToken) {
        if (sequence.tokens[j - 1] instanceof VariableToken) {
          if (
            token.key === "=" &&
            !(sequence.tokens[j + 1] instanceof NumberToken) &&
            !(sequence.tokens[j + 1] instanceof VariableToken) &&
            !(sequence.tokens[j + 1] instanceof SequenceReferenceToken) &&
            !(sequence.tokens[j + 1] instanceof TextToken)
          )
            throw new WittongueEditorMessage(
              `Expected a number or variable following an assignment operator`,
              token.linePosition,
              MarkerSeverity.Error
            );
        }

        if (sequence.tokens[j - 1] instanceof OperatorToken) {
          throw new WittongueEditorMessage(
            `Cannot have two operators in a row`,
            token.linePosition,
            MarkerSeverity.Error
          );
        }
      }
      if (token instanceof TextToken) {
        if (prevToken instanceof TextToken && sequence.depth > 0) {
          throw new WittongueEditorMessage(
            `Cannot have two text tokens in a row. If you want both strings to show, use a '+' operator`,
            token.linePosition,
            MarkerSeverity.Error
          );
        }
        if (prevToken instanceof VariableToken) {
          throw new WittongueEditorMessage(
            `Cannot have a variable followed by a string. If you want to use the variable in the string, use a nested '{' and '}' syntax. If you want to add it to the string, use '+'.`,
            token.linePosition,
            MarkerSeverity.Error
          );
        }
      }
      if (token instanceof VariableToken) {
        if (prevToken instanceof DeclareVariableToken) {
          if (
            !(sequence.tokens[j + 1] instanceof OperatorToken) ||
            sequence.tokens[j + 1].key !== "="
          )
            throw new WittongueEditorMessage(
              `Expected assignment following a new variable declaration`,
              token.linePosition,
              MarkerSeverity.Error
            );
        }

        if (prevToken instanceof VariableToken) {
          throw new WittongueEditorMessage(
            `Cannot have two variables in a row. Did you mean to use an operator?`,
            token.linePosition,
            MarkerSeverity.Error
          );
        }
      }
    }
    return outcome;
  }
}

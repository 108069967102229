import { Box, ChakraProvider, Text } from "@chakra-ui/react";
import TraitMaker from "./trait-maker/TraitMaker";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AssetCreationNavigationMenu from "./main-page/AssetCreationNavigationMenu";
import ErrorPage from "./main-page/ErrorPage";
import { webControllerTheme } from "ui/styles";
import EncounterEditor from "./encounter-editor/components/EncounterEditor";
import HeaderLayout from "./shared-layout/HeaderLayout";
import EquationMaker from "./equation-maker/EquationMaker";
const router = createBrowserRouter(
  [
    {
      element: <HeaderLayout />,
      children: [
        {
          path: "/",
          element: <AssetCreationNavigationMenu />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/trait-maker",
          element: <TraitMaker />,
        },
        {
          path: "/encounter-editor",
          element: <EncounterEditor />,
        },
        {
          path: "/equation-maker",
          element: <EquationMaker />,
        },
      ],
    },
  ],
  {
    basename: "/",
  }
);
function App() {
  return (
    <ChakraProvider theme={webControllerTheme}>
      <Box layerStyle={"base"}>
        <RouterProvider router={router} />
      </Box>
    </ChakraProvider>
  );
}

export default App;

import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  HStack,
  Heading,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import Editor from "@monaco-editor/react";
import { useEffect, useState } from "react";
import { WittongueEditorMessage, defineWittongue } from "@wittongue/editor";
import { CollapsibleSection } from "ui/base-components";
import { samples } from "../encounterSamples";
import WittongueCompiler, { CompiledEncounter } from "@wittongue/compiler";
export default function WittongueEditor({
  setCompiledEncounter,
}: {
  setCompiledEncounter: (compiledEncounter: CompiledEncounter) => void;
}) {
  const [compiler, setCompiler] = useState<WittongueCompiler | null>(
    new WittongueCompiler()
  );
  const [selectedSample, setSelectedSample] = useState(samples[1]);
  const [alwaysUpdate, setAlwaysUpdate] = useBoolean(true);
  const [compiledOutput, setCompiledOutput] = useState<CompiledEncounter>();
  const [startingPartKey, setStartingPartKey] = useState<string>("");
  const [monaco, setMonaco] = useState<
    typeof import("monaco-editor/esm/vs/editor/editor.api") | null
  >(null);
  useEffect(() => {
    const res = defineWittongue().then((monaco) => {
      if (monaco) {
        setMonaco(monaco);
      }
    });
  }, []);
  useEffect(() => {
    if (compiledOutput && alwaysUpdate) {
      setCompiledEncounter(compiledOutput);
    }
  }, [compiledOutput, alwaysUpdate]);
  useEffect(() => {
    if (startingPartKey && compiledOutput) {
      setCompiledOutput({ ...compiledOutput, startingPartKey });
    }
  }, [startingPartKey, compiledOutput]);
  useEffect(() => {
    if (monaco) {
      const model = monaco.editor.getModels()[0];
      model.updateOptions({
        tabSize: 2,
        indentSize: 2,
      });
      model.onDidChangeContent(() => {
        if (!compiler) {
          throw new Error("Compiler not instantiated!");
        }
        //for now, just compile to json and throw errors directly to the editor
        try {
          // const output = WittongueCompiler.compileToJson(model.getValue());
          const output = compiler.compileFromRaw(model.getValue());
          const compiledEncounter = output.compiledEncounter;

          compiledEncounter.startingPartKey = startingPartKey;

          setCompiledOutput(compiledEncounter);
          //model markers are cleared on each change, so we need to re-add them
          monaco.editor.setModelMarkers(model, "owner", output.warnings);
        } catch (e: any) {
          if (e instanceof WittongueEditorMessage) {
            monaco.editor.setModelMarkers(model, "owner", [
              {
                startLineNumber: e.startLineNumber,
                startColumn: e.startColumn,
                endLineNumber: e.endLineNumber,
                endColumn: e.endColumn,
                message: e.message,
                severity: e.severity,
              },
            ]);
          } else {
            console.error(e);
          }
        }
      });

      //compile the initial story
      //TODO update
      try {
        if (!compiler) {
          throw new Error("Compiler not instantiated!");
        }
        const output = compiler.compileFromRaw(
          monaco.editor.getModels()[0].getValue()
        );
        if (startingPartKey) {
          output.compiledEncounter.startingPartKey = startingPartKey;
        }
        setCompiledOutput(output.compiledEncounter);
        //model markers are cleared on each change, so we need to re-add them
        monaco.editor.setModelMarkers(model, "owner", output.warnings);
      } catch (e: any) {
        if (e instanceof WittongueEditorMessage) {
          monaco.editor.setModelMarkers(model, "owner", [
            {
              startLineNumber: e.startLineNumber,
              startColumn: e.startColumn,
              endLineNumber: e.endLineNumber,
              endColumn: e.endColumn,
              message: e.message,
              severity: e.severity,
            },
          ]);
        } else {
          console.error(e);
        }
      }
    }
  }, [monaco]);
  return (
    <Box>
      <Heading
        letterSpacing={2}
        fontFamily={"Luckiest Guy"}
        textAlign={"center"}
      >
        Wittongue Editor
      </Heading>
      <Editor
        options={{
          autoIndent: "full",
        }}
        height="70vh"
        defaultLanguage="wittongue"
        defaultValue={selectedSample}
        theme="myCustomTheme"
        onChange={(value, event) => {
          if (value) {
            // parseToJson(value);
            // console.log(monaco?.editor.tokenize(value, "wittongue"));
          }
        }}
      />
      <ButtonGroup>
        {compiledOutput && (
          <Button
            onClick={() => {
              console.log(compiledOutput.parts);
            }}
          >
            {`Print Encounter to Console`}
          </Button>
        )}
        {!alwaysUpdate && (
          <Button
            onClick={() => {
              if (compiledOutput) setCompiledEncounter(compiledOutput);
            }}
          >
            See Preview
          </Button>
        )}
      </ButtonGroup>
      {compiledOutput && (
        <CollapsibleSection title="Encounter Options">
          <Text w="100%">Select starting Encounter Part:</Text>
          <RadioGroup
            w={"100%"}
            defaultValue={
              //first value
              compiledOutput.parts.find((part) => !part.key.includes("."))?.key
            }
            value={startingPartKey}
            onChange={setStartingPartKey}
          >
            <SimpleGrid columns={2}>
              {compiledOutput.parts.map((part, index) => {
                if (part.key.includes(".")) {
                  return null;
                }
                return (
                  <Radio value={part.key} key={index}>
                    {part.key}
                  </Radio>
                );
              })}
            </SimpleGrid>
          </RadioGroup>
        </CollapsibleSection>
      )}
      <CollapsibleSection title="Editor Options">
        <Checkbox
          onChange={() => setAlwaysUpdate.toggle()}
          isChecked={alwaysUpdate}
        >
          Always Update
        </Checkbox>
      </CollapsibleSection>
    </Box>
  );
}

import { LinePosition } from "@wittongue/editor";
import { TokenSequence, Choice } from "@wittongue/compiler";
export class EncounterPart {
  key: string;
  linePosition: LinePosition;
  contentSequences: TokenSequence[];
  nestedSequences: TokenSequence[];
  choices: Choice[];
  rawContent: string;
  defaultDivert?: string;
  constructor(key: string, linePosition: LinePosition) {
    this.key = key;
    this.contentSequences = [];
    this.choices = [];
    this.rawContent = "";
    this.nestedSequences = [];
    this.linePosition = linePosition;
  }
  /**Adds content to the raw text that will be parsed into Tokens */
  addRawContent(content: string) {
    this.rawContent += content;
  }
  addFinishedLine(line: TokenSequence) {
    this.contentSequences.push(line);
  }

  addChoice(choice: Choice) {
    this.choices.push(choice);
  }
}

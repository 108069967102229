import { MarkerSeverity } from "monaco-editor";
import { LinePosition } from ".";

export class WittongueEditorMessage extends Error {
  startLineNumber: number;
  startColumn: number;
  endLineNumber: number;
  endColumn: number;
  severity: MarkerSeverity;

  constructor(
    message: string,
    linePosition: LinePosition,
    severity: MarkerSeverity
  ) {
    super(message);
    this.name = "WittongueError";
    this.startLineNumber = linePosition.startLineNumber;
    this.startColumn = linePosition.startColumnNumber;
    this.endLineNumber = linePosition.endLineNumber;
    this.endColumn = linePosition.endColumnNumber;
    this.severity = severity;
  }
}

import { Token } from ".";
import { LinePosition } from "@wittongue/editor";
/**
 * A sequence of tokens that are grouped together. This is used for logic sequences and treated as lines.
 */
export class TokenSequence {
  key: string;
  tokens: Token[] = [];
  linePosition: LinePosition;
  depth: number;
  constructor(startLineNumber: number, startColumnNumber: number, depth = 0) {
    this.key = `sequence_l${startLineNumber}_c${startColumnNumber}`;
    this.linePosition = {
      startLineNumber,
      startColumnNumber,
      endLineNumber: startLineNumber,
      endColumnNumber: startColumnNumber,
    };
    this.depth = depth;
  }

  /**
   *
   * @param endLineNumber
   * @param endColumnNumber
   * Closes the sequence and sets the end line and column numbers
   */
  finishSequence(endLineNumber: number, endColumnNumber: number) {
    this.linePosition.endLineNumber = endLineNumber;
    this.linePosition.endColumnNumber = endColumnNumber;
  }
  /**
   * Adds a token to the sequence
   * @param token  The token to add
   */
  addToken(token: Token) {
    this.tokens.push(token);

    //apply some checks here
  }
  /**
   *
   * @returns the last token in the sequence
   */
  lastToken() {
    return this.tokens[this.tokens.length - 1];
  }
}

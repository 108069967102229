const fullSample = `//Start writing your story here
=part enter
	//by default, if there's no actor or narrator established, it automatically starts on narration
	As you approach the house, you hear loud music blaring from within.
	+Approach the house
    {fight_bouncers? =>unwelcome :\`\`}
    =>approach_party
  
	+This was a bad idea.
	  =>end
=end
=part approach_party
  $narrate
  As you walk to the door, a burly blonde haired man steps in your way.
  $bouncer_1
  Can I help you?
  $narrate
  He looks you up and down cooly.
  +I'm here for the party.
    $bodyguard_2
    Let's see your invite.
    ++{hasItem(\`party_invite\`)} Here you go.
      =>enter_party
  **{hasTrait(\`charm\`)} I think you could make an exception for me.
  -no_invite
  $bouncer_2
  I'm sorry, you don't have an invitation to the party.
  There's no way you can get in.
  *Watch me!
    =>fight_bouncers
  +Ok. We'll be back.
    $narrate
    The bodyguards have already stopped listening.
    =>end

=end
=part fight_bouncers

=end
=part unwelcome
  $narrate
  Before you can even open your mouth one of the bouncers run off.
  $exit:bouncer_2

  It looks like the bouncer was calling for backup! This'll be even tougher than last time.
  +This isn't worth it.
    You decide to leave.
    =>end
  +The more the merrier!
    =>fight_bouncers

=end
=part enter_party
  You made it to the party!
  =>end
=end`;
const logicSample = `
=part test
{
  var a = 5;
  var b = a+2;
}
Some variables are \${undeclared}

some functions are \${function()}

{make_bigger(3,12)}
=end
`;

const shortSample = `
=part part1
Hi there
This is a {\`nested\`} string
=end
`;
const bombSample = `=part bomb
  The bomb is ticking ominously.
  *(cut_red)Cut the red wire
    {bomb.cut_blue? =>bomb : =>explosion }
  *(cut_blue)Cut the blue wire
    You snip the blue wire.
    So far so good.
  *(cut_green)Cut the green wire.
  {bomb.cut_red? =>bomb : =>explosion }
  +(defuse_button)Press defuse
    {defuse? =>defused:\`\`}
    Nothing happens.
=end
=part explosion
  You see a bright flash of white and then...
  =>DONE
=end
=part defused
  You breathe a giant sigh of relief. Good work!
  =>DONE
=end
`;
const shortSample2 = `
=part start
  $narrate
  You see a man
  $man
  +Say Hi.
    $man
    Hi there.
    ++ What's your name?
      $man
      Bob
    ++What's your favorite color?
      $man
      Blue.
  +Say bye.
    $man
    See you later!
    -walk_away
    You walk away.
    But as you're about to leave...
    =>start
=end
`;
export const samples: string[] = [
  shortSample,
  shortSample2,
  fullSample,
  logicSample,
  bombSample,
];

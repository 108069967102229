import { Token, TokenType } from ".";
import { LinePosition } from "@wittongue/editor";
export class VariableToken extends Token {
  variableName: string;
  constructor(variableName: string, linePosition: LinePosition) {
    const key = `variable_${variableName}`;
    super(key, TokenType.VARIABLE, linePosition);
    this.variableName = variableName;
  }
}

import { ButtonGroup, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
const AssetCreationNavigationMenu = () => {
  const navigate = useNavigate();
  return (
    <ButtonGroup>
      <Button
        onClick={() => {
          navigate("/trait-maker");
        }}
      >
        {`Trait Maker`}
      </Button>
      <Button
        onClick={() => {
          navigate("/encounter-editor");
        }}
      >
        {`Encounter Editor`}
      </Button>
    </ButtonGroup>
  );
};

export default AssetCreationNavigationMenu;

import { Token, TokenType } from ".";
import { LinePosition } from "@wittongue/editor";

export class OperatorToken extends Token {
  precendence: number;
  constructor(key: string, precendence: number, linePosition: LinePosition) {
    super(key, TokenType.OPERATOR, linePosition);
    this.precendence = precendence;
  }
}

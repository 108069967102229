import { MarkerSeverity } from "monaco-editor";
import {
  Token,
  TokenType,
  TokenSequence,
  EncounterPart,
  CompiledEncounter,
  Choice,
  TextToken,
  SequenceReferenceToken,
  DivertToken,
  StageDirectionToken,
  FunctionCallToken,
  DeclareVariableToken,
  OperatorToken,
  ControlFlowToken,
  VariableToken,
  NumberToken,
} from "@wittongue/compiler";
import {
  WittongueEditorMessage,
  LinePosition,
  EncounterPartStack,
  SequenceStack,
  TokenStack,
  CompiledEncounterValidator,
} from "@wittongue/editor";
const keywords = ["var"];

const nonTextCharacters = /[+=*><-]/;
export class WittongueCompiler {
  standAlonePartStacks: EncounterPartStack[];
  partStack?: EncounterPartStack;
  closedParts: EncounterPart[];
  warnings: WittongueEditorMessage[];
  globalLines: EncounterPart;

  constructor() {
    this.standAlonePartStacks = [];
    this.closedParts = [];
    this.warnings = [];
    this.globalLines = new EncounterPart("global", {
      startLineNumber: 1,
      startColumnNumber: 1,
      endLineNumber: 1,
      endColumnNumber: 1,
    });
    this.resetCompiler();
  }

  startEncounterPart(
    key: string,
    linePosition: LinePosition,
    useAbsoluteKey?: boolean
  ) {
    if (!this.partStack) {
      throw new WittongueEditorMessage(
        `Trying to start a part without a part stack`,
        linePosition,
        MarkerSeverity.Error
      );
    }
    if (this.partStack.count() > 0 && !useAbsoluteKey) {
      key = `${this.partStack.peek().key}.${key}`;
    }
    const part = new EncounterPart(key, linePosition);
    if (this.closedParts.find((p) => p.key === key)) {
      throw new WittongueEditorMessage(
        `Part with key {${key}} already exists`,
        linePosition,
        MarkerSeverity.Error
      );
    }
    this.partStack.push(part);
  }

  endEncounterPart(linePosition: LinePosition) {
    if (!this.partStack) {
      throw new WittongueEditorMessage(
        `Trying to end a part without a part stack`,
        linePosition,
        MarkerSeverity.Error
      );
    }
    const part = this.partStack.pop();
    if (!part) {
      throw new WittongueEditorMessage(
        `Trying to end a part that doesn't exist`,
        linePosition,
        MarkerSeverity.Error
      );
    }
    part.linePosition.endLineNumber = linePosition.endLineNumber;
    part.linePosition.endColumnNumber = linePosition.endColumnNumber;
    this.closedParts.push(part);
  }
  /**
   * Resets the compiler to its initial state. Call this before compiling a new encounter.
   */
  resetCompiler() {
    this.standAlonePartStacks = [];
    this.closedParts = [];
    this.warnings = [];
    this.globalLines = new EncounterPart("global", {
      startLineNumber: 1,
      startColumnNumber: 1,
      endLineNumber: 1,
      endColumnNumber: 1,
    });
  }

  /**
   * This function handles all of the crucial line separation. Essentially it looks for:
   * 1) Global lines: lines that are not in a part
   * 2) Part lines: lines that indicate the start or end of a part (denoted by the number of = in front of the 'part' keyword)
   * 3) Gathers: lines that indicate a gather, and by extension the start of a new EncounterPart
   * 4) Choices: lines that indicate a choice, and by extension the start of a new EncounterPart. Choices can be repeating(+) or once (*)
   * @param input
   */
  parseIntoParts(input: string): EncounterPart[] {
    const lines = input.split("\n");
    let startLineNumber = 0;
    let startColumnNumber = 1;
    let endLineNumber = 0;
    let endColumnNumber = 1;
    let inComment = false;

    for (let i = 0; i < lines.length; i++) {
      startLineNumber = i + 1; //monaco editor uses 1-based line numbers
      endLineNumber = startLineNumber;
      //find number of whitespace characters before the first non-whitespace character
      const whitespace = lines[i].match(/^(\s*)/)?.[1];
      startColumnNumber = whitespace ? whitespace.length + 1 : 1;
      let trimmedLine = lines[i].trim();
      endColumnNumber = startColumnNumber + trimmedLine.length + 2;

      const linePosition: LinePosition = {
        startLineNumber,
        startColumnNumber,
        endLineNumber,
        endColumnNumber,
      };
      //matching comments

      //handle comments
      trimmedLine = trimmedLine.replace(/\/\/.*\s*/, "");
      if (trimmedLine.match(/.*\*\//)) {
        trimmedLine = trimmedLine.replace(/.*\*\//, "");
        inComment = false;
      }
      if (inComment) {
        continue;
      }
      if (trimmedLine.match(/\/\*.*/)) {
        trimmedLine = trimmedLine.replace(/\/\*.*/, "");
        inComment = true;
      }

      if (trimmedLine.match(/[=]+part/)) {
        const parts = trimmedLine.match(/(=+)part (.*)/);
        const depth = parts?.[1]?.length;
        if (depth! > 1 && !this.partStack) {
          throw new WittongueEditorMessage(
            `Use a depth of 1 to start a new part.`,
            linePosition,
            MarkerSeverity.Error
          );
        }
        if (depth! != this.standAlonePartStacks.length + 1) {
          throw new WittongueEditorMessage(
            `The number of '=' used matters. The current part depth is ${
              this.standAlonePartStacks.length
            }, so you should use ${this.standAlonePartStacks.length + 1} '='s.`,
            linePosition,
            MarkerSeverity.Error
          );
        }
        if (depth! <= this.standAlonePartStacks.length - 1) {
          throw new WittongueEditorMessage(
            `Encounter Part's depth (${depth}) is more shallow than the part it belongs to at depth: ${
              this.partStack?.count() || 0
            }.`,
            linePosition,
            MarkerSeverity.Error
          );
        }
        let partKey = parts?.[2];
        if (!partKey) {
          throw new WittongueEditorMessage(
            `Part should have a key/name`,
            linePosition,
            MarkerSeverity.Error
          );

          // partKey = `part_l${startLineNumber}`;
        }
        if (depth! > 1) {
          partKey = `${this.partStack!.peek().key}.${partKey}`;
        }
        this.standAlonePartStacks.push(new EncounterPartStack());
        this.partStack =
          this.standAlonePartStacks[this.standAlonePartStacks.length - 1];
        this.startEncounterPart(partKey, linePosition);
      }
      //end of part
      else if (trimmedLine.match(/^\=+end$/)) {
        if (!this.partStack) {
          throw new WittongueEditorMessage(
            `Trying to end a part without a part stack`,
            linePosition,
            MarkerSeverity.Error
          );
        }
        const endLineParts = trimmedLine.match(/(=+)end(.*)/);
        const depth = endLineParts?.[1]?.length;
        if (!depth) {
          throw new WittongueEditorMessage(
            `part end must have at least one =`,
            linePosition,
            MarkerSeverity.Error
          );
        }
        if (depth !== this.standAlonePartStacks.length) {
          throw new WittongueEditorMessage(
            `end tag at depth: ${depth} is not equal to the end tag of the current part ${this.standAlonePartStacks.length}.`,
            linePosition,
            MarkerSeverity.Error
          );
        }
        const hasOtherContent = endLineParts?.[2];
        if (hasOtherContent) {
          throw new WittongueEditorMessage(
            `==end should be on its own line. Saw: ${hasOtherContent}`,
            linePosition,
            MarkerSeverity.Error
          );
        }
        //use the most recently added part
        while (this.partStack.count() > 0) {
          try {
            this.endEncounterPart(linePosition);
          } catch (error) {
            //make the error more specific.
            throw new WittongueEditorMessage(
              `==end does not have a matching starting ==part`,
              linePosition,
              MarkerSeverity.Error
            );
          }
        }

        this.standAlonePartStacks.pop();
        if (this.standAlonePartStacks.length > 0) {
          this.partStack =
            this.standAlonePartStacks[this.standAlonePartStacks.length - 1];
        } else {
          this.partStack = undefined;
        }
      } else if (trimmedLine.startsWith("+") || trimmedLine.startsWith("*")) {
        if (!this.partStack) {
          throw new WittongueEditorMessage(
            `Trying to add a choice without a part stack`,
            linePosition,
            MarkerSeverity.Error
          );
        }
        const mode = trimmedLine.startsWith("+") ? "repeating" : "once";
        const choiceDepth =
          mode === "repeating"
            ? trimmedLine.match(/\++/)?.[0]?.length
            : trimmedLine.match(/\*+/)?.[0]?.length;
        if (!choiceDepth) {
          throw new WittongueEditorMessage(
            `choice must start with + or *`,
            linePosition,
            MarkerSeverity.Error
          );
        }

        //choices should only ever be as nested/deep as the current part they are in.
        if (choiceDepth > this.partStack.count()) {
          throw new WittongueEditorMessage(
            `choice is more deep (${choiceDepth}) than the part it belongs to or the choices above.`,
            linePosition,
            MarkerSeverity.Error
          );
        }

        const textParts = trimmedLine.match(
          /[+*]+(?:\(([a-zA-Z0-9_]+)\))?(\{.*\})?\s*(.+)/
        );
        const key = textParts?.[1];
        const condition = textParts?.[2];
        const text = textParts?.[3];
        if (!text) {
          throw new WittongueEditorMessage(
            `Choice must have text`,
            linePosition,
            MarkerSeverity.Error
          );
        }

        if (text.match(/[^a-zA-Z0-9_.,?!' \[\]"]+/)) {
          throw new WittongueEditorMessage(
            `Choice text must only contain letters, numbers, underscores and periods. Diverts and logic must be on the next line.`,
            linePosition,
            MarkerSeverity.Error
          );
        }
        //for each level less than the current depth, we need to close the part and add it to the finished parts
        //for example, if we have a part stack of 3 and a choice depth of 1, we need to close 2 parts
        //if we have a part stack of 3 and a choice depth of 2, we need to close 1 part

        //we need to close the current part and add it to the finished parts
        while (choiceDepth < this.partStack.count()) {
          try {
            this.endEncounterPart(linePosition);
          } catch (err) {
            throw new WittongueEditorMessage(
              `choice at ${startLineNumber} is more shallow than the deepest part it belongs to.`,
              linePosition,
              MarkerSeverity.Error
            );
          }
        }

        const generatedChoicePartName = key ?? `choice_l${startLineNumber}`;
        //add the choice to the current part at the right level
        let divertTo = generatedChoicePartName;
        if (this.partStack.count() > 0) {
          divertTo = `${this.partStack.peek().key}.${divertTo}`;
        }
        this.partStack
          .peek()
          .addChoice(
            new Choice(
              choiceDepth,
              mode,
              text,
              divertTo,
              linePosition,
              condition
            )
          );
        this.startEncounterPart(generatedChoicePartName, linePosition);
      } else if (trimmedLine.startsWith("-")) {
        if (!this.partStack) {
          throw new WittongueEditorMessage(
            `Trying to add a gather without a part stack`,
            linePosition,
            MarkerSeverity.Error
          );
        }
        //count number of - at the front of the line (must be 1 or more because we check the line starts with -)
        const gatherParts = trimmedLine.match(/(-+)(.*)/);
        const gatherDepth = gatherParts?.[1]?.length;
        if (!gatherDepth) {
          throw new WittongueEditorMessage(
            `gather must start with -`,
            linePosition,
            MarkerSeverity.Error
          );
        }
        //gathers should only ever be as nested as the current part.
        if (gatherDepth >= this.partStack.count()) {
          throw new WittongueEditorMessage(
            `gather is more deep (${gatherDepth}) than the part it belongs to or the choices above.`,
            linePosition,
            MarkerSeverity.Error
          );
        }
        let gatherKey = gatherParts?.[2];
        if (!gatherKey) {
          this.warnings.push(
            new WittongueEditorMessage(
              `gather can have a key for clarity`,
              linePosition,
              MarkerSeverity.Warning
            )
          );
          gatherKey = `gather_l${startLineNumber}`;
        }
        if (gatherKey.match(/[^a-zA-Z_.0-9]+/)) {
          //invalid characters matched
          throw new WittongueEditorMessage(
            `gather name must only contain letters, numbers, underscores and periods. e.g. gather_1 or gather_1.1`,
            linePosition,
            MarkerSeverity.Error
          );
        }
        //close all of the choices/parts at the same depth or deeper than the gather
        while (gatherDepth < this.partStack.count()) {
          try {
            this.endEncounterPart(linePosition);
          } catch (err) {
            throw new WittongueEditorMessage(
              `gather at ${startLineNumber} is more shallow than the deepest part it belongs to.`,
              linePosition,
              MarkerSeverity.Error
            );
          }
        }
        gatherKey = `${this.partStack.peek().key}.${gatherKey}`;
        const currentChoices = this.partStack.peek().choices;
        for (let i = 0; i < currentChoices.length; i++) {
          const correspondingPart = this.closedParts.find(
            (p) => p.key === currentChoices[i].divertTo
          );
          if (correspondingPart) {
            this.addGather(correspondingPart, `${gatherKey}`);
          }
        }
        // partStack.peek().addGather(`${partStack.peek().key}.${gatherKey}`);

        //close the current part since the gather is breaking the part into two parts
        this.endEncounterPart(linePosition);
        //open the gather part
        this.startEncounterPart(gatherKey, linePosition, true);
      }
      //add the lines to the current encounter part's raw content
      else {
        if (this.partStack && this.partStack.count() > 0) {
          //add back in the whitespace to preserve column numbers
          this.partStack.peek().addRawContent(whitespace + trimmedLine + "\n");
        } else {
          if (trimmedLine.length > 0) {
            this.globalLines.addRawContent(trimmedLine);
          }
        }
      }
    }

    //check for any open parts
    if (this.partStack && this.partStack.count() > 0) {
      throw new WittongueEditorMessage(
        `Part ${this.partStack.peek().key} is not closed`,
        this.partStack.peek().linePosition,
        MarkerSeverity.Error
      );
    }
    if (this.partStack) {
      this.warnings.push(
        new WittongueEditorMessage(
          `Part stack is not empty. Part: "${
            this.partStack.peek().key
          }" is not closed`,
          this.partStack.peek().linePosition,
          MarkerSeverity.Warning
        )
      );
    }

    return this.closedParts;
  }
  addGather(part: EncounterPart, divertTo: string) {
    if (part.choices.length > 0) {
      for (let i = 0; i < part.choices.length; i++) {
        const correspondingPart = this.closedParts.find(
          (p) => p.key === part.choices[i].divertTo
        );
        if (correspondingPart) {
          this.addGather(correspondingPart, divertTo);
        }
      }
    }
    if (part.defaultDivert) {
      if (part.defaultDivert === divertTo) {
        return;
      }
      const correspondingPart = this.closedParts.find(
        (p) => p.key === part.defaultDivert
      );
      if (correspondingPart) {
        this.addGather(correspondingPart, divertTo);
      }
    } else {
      part.defaultDivert = divertTo;
    }
  }
  parseRawContentIntoTokenSequences(part: EncounterPart) {
    const nestedSequenceStack = new SequenceStack();
    const contentSequences: TokenSequence[] = [];
    const nestedSequences: TokenSequence[] = [];
    let nestedTokenStack = new TokenStack();
    let lastLineNumber = part.linePosition.startLineNumber + 1;
    let columnNumber = 1;
    /**Refers to the expected type of token based on the current characters seen so far. */
    let currentTokenType: TokenType | null = null;

    /**The current characters being collected for the token. This is useful for example if we see a "+", but we don't know if it is a "+=" or a "++" */
    let currentCharacters = "";
    let currentToken: Token | null = null;
    contentSequences.push(new TokenSequence(lastLineNumber, 1)); //start with the first sequence already pushed into the array
    let currentSequence = contentSequences[0];
    let lineNumber = part.linePosition.startLineNumber + 1;
    for (let i = 0; i < part.rawContent.length; i++) {
      let ch = part.rawContent[i];
      if (
        ch === " " &&
        !(currentToken instanceof TextToken) &&
        currentSequence.depth === 0 &&
        currentSequence.tokens.length === 0
      ) {
        //ignore leading whitespace, but keep track of the column number
        columnNumber++;
        continue;
      }

      //start nested logic
      if (ch === "{") {
        //if we were in the middle of a text token, then we need to remember that by ending the token, and pushing a new one to the stack
        if (currentToken) {
          nestedSequenceStack.peek().addToken(currentToken);
          nestedTokenStack.push(currentToken);
          currentToken = null;
        }
        if (currentCharacters) {
          currentSequence.addToken(createNonTextToken(currentCharacters));
          currentCharacters = "";
          currentTokenType = null;
        }
        //start a new sequence
        const sequence = new TokenSequence(
          lineNumber,
          columnNumber,
          nestedSequenceStack.count() + 1
        );
        nestedSequenceStack.push(sequence);
        currentSequence.addToken(new SequenceReferenceToken(sequence));
        currentSequence = sequence;
        //push new sequence onto nested stack
      }
      //end nested logic
      else if (ch === "}") {
        //if we had current characters, then we need to parse them into a token
        const sequence = nestedSequenceStack.pop();
        if (!sequence) {
          throw new WittongueEditorMessage(
            `Trying to end a sequence that doesn't exist`,
            nestedSequenceStack.peek().linePosition,
            MarkerSeverity.Error
          );
        }
        currentSequence = sequence;
        if (currentCharacters) {
          currentSequence.addToken(createNonTextToken(currentCharacters));
        }

        //end the current sequence
        sequence.finishSequence(lineNumber, columnNumber);
        nestedSequences.push(sequence);
        if (nestedSequenceStack.count() === 0) {
          //we are back to the main sequence
          currentSequence = contentSequences[contentSequences.length - 1];
        } else {
          currentSequence = nestedSequenceStack.peek();
        }
        //if we had an interrupted token, then we need to make it the current token again
        if (nestedTokenStack.peek()) {
          if (nestedTokenStack.peek().type === TokenType.TEXT) {
            nestedTokenStack.pop();
            currentToken = new TextToken("", {
              startLineNumber: lineNumber,
              startColumnNumber: columnNumber,
              endLineNumber: lineNumber,
              endColumnNumber: columnNumber,
            });
          }
          if (nestedTokenStack.peek().type === TokenType.FUNCTION) {
            currentToken = nestedTokenStack.pop()!;
          }
        }
      }

      //newline
      else if (ch === "\n") {
        if (currentSequence.depth > 0) {
          continue;
        }
        //check if the rest of the rawcontent is just blanks
        if (part.rawContent.substring(i).match(/^\s*$/)) {
          break;
        }
        //start a newline
        lineNumber++;
        currentSequence = new TokenSequence(lineNumber, 1);
        contentSequences.push(currentSequence);

        columnNumber = 1;
      }
      //see a divert
      else if (ch === "=" && part.rawContent[i + 1] === ">") {
        if (currentToken && currentToken instanceof TextToken) {
          throw new WittongueEditorMessage(
            `Cannot have divert inside of text`,
            currentToken.linePosition,
            MarkerSeverity.Error
          );
        }
        const divertRegex = /=>([a-zA-Z_.0-9]+)/;
        const match = part.rawContent.substring(i).match(divertRegex);
        if (match) {
          const divertTo = match[1];
          const divert = new DivertToken(
            `divert_l${lineNumber}_c${i}`,
            divertTo,
            {
              startLineNumber: lineNumber,
              startColumnNumber: columnNumber,
              endLineNumber: lineNumber,
              endColumnNumber: columnNumber + divertTo.length,
            }
          );
          currentSequence.addToken(divert);
          i += match[0].length - 1;
          columnNumber += match[0].length - 1;
        } else {
          throw new WittongueEditorMessage(
            `Expected a divert to follow '=>'. Operators are not allowed in the base text.`,
            {
              startLineNumber: lineNumber,
              startColumnNumber: columnNumber,
              endLineNumber: lineNumber,
              endColumnNumber: columnNumber,
            },
            MarkerSeverity.Error
          );
        }
      } else if (ch === "$") {
        if (currentToken && currentToken instanceof TextToken) {
          throw new WittongueEditorMessage(
            `Cannot have a stage direction inside of text.`,
            currentToken.linePosition,
            MarkerSeverity.Error
          );
        }
        const stageDirectionRegex = /\$([a-zA-Z_:0-9]+)/;
        const match = part.rawContent.substring(i).match(stageDirectionRegex);
        if (match) {
          const stageDirection = match[1];
          const stageDirectionToken = new StageDirectionToken(stageDirection, {
            startLineNumber: lineNumber,
            startColumnNumber: columnNumber,
            endLineNumber: lineNumber,
            endColumnNumber: columnNumber + stageDirection.length,
          });
          currentSequence.addToken(stageDirectionToken);
          i += match[0].length - 1;
          columnNumber += match[0].length - 1;
        } else {
          throw new WittongueEditorMessage(
            `Expected a variable to follow '$'. Operators are not allowed in the base text.`,
            {
              startLineNumber: lineNumber,
              startColumnNumber: columnNumber,
              endLineNumber: lineNumber,
              endColumnNumber: columnNumber,
            },
            MarkerSeverity.Error
          );
        }
      }
      //all other characters are context dependent
      else {
        //if we are not in a nested sequence, then we can assume everything except { and } and \n and non-text characters are text
        if (currentSequence.depth === 0) {
          const remainingContent = part.rawContent.substring(i);
          // const invalidContent = remainingContent.match(
          //   /[^a-zA-Z0-9_.,?!'\s"()</>]+/
          // );
          // if (invalidContent) {
          //   throw new WittongueEditorMessage(
          //     `Invalid character in base text: ${invalidContent[0]}`,
          //     {
          //       startLineNumber: lineNumber,
          //       startColumnNumber: columnNumber,
          //       endLineNumber: lineNumber,
          //       endColumnNumber: columnNumber,
          //     },
          //     MarkerSeverity.Error
          //   );
          // }
          const acceptableText = /([a-zA-Z?!.,:; '()0-9"</>]+)(?:[{\n$]|=>)/;
          //add all characters up to the next { or \n to the current line
          const content = remainingContent.match(acceptableText);
          if (content) {
            const text = content[1];
            currentSequence.addToken(
              new TextToken(text, {
                startLineNumber: lineNumber,
                startColumnNumber: columnNumber,
                endLineNumber: lineNumber,
                endColumnNumber: columnNumber + text.length - 1,
              })
            );
            i += text.length - 1;
            columnNumber += text.length - 1;
          } else {
            throw new WittongueEditorMessage(
              `Invalid character in: ${part.rawContent.substring(i)}`,
              {
                startLineNumber: lineNumber,
                startColumnNumber: columnNumber,
                endLineNumber: lineNumber,
                endColumnNumber: columnNumber,
              },
              MarkerSeverity.Error
            );
          }
        }
        //nested layer, now we need to pay attention to the characters
        else {
          // we see the start of a text token or the end of one
          if (ch === "`") {
            if (currentCharacters) {
              currentSequence.addToken(createNonTextToken(currentCharacters));
              currentCharacters = "";
              currentTokenType = null;
            }

            if (currentToken && currentToken instanceof TextToken) {
              currentSequence.addToken(currentToken);
              currentToken = null;
            } else {
              currentToken = new TextToken("", {
                startLineNumber: lineNumber,
                startColumnNumber: columnNumber,
                endLineNumber: lineNumber,
                endColumnNumber: columnNumber,
              });
            }
          }
          //match operators
          else if (ch.match(/[+=*><!-]/)) {
            contributeToCurrentCharactersOrGenerateToken(
              TokenType.OPERATOR,
              ch
            );
          }
          //match an opening bracket
          else if (ch === "(") {
            if (currentCharacters.length > 0) {
              if (keywords.includes(currentCharacters)) {
                throw new WittongueEditorMessage(
                  `Cannot name function ${currentCharacters}, this is a reserved keyword`,
                  {
                    startLineNumber: lineNumber,
                    startColumnNumber:
                      columnNumber - currentCharacters.length - 1,
                    endLineNumber: lineNumber,
                    endColumnNumber: columnNumber - 1,
                  },
                  MarkerSeverity.Error
                );
              }

              currentTokenType = TokenType.FUNCTION;
            }
            currentCharacters += ch;
            const linePosition = {
              startLineNumber: lineNumber,
              startColumnNumber: columnNumber - currentCharacters.length - 1,
              endLineNumber: lineNumber,
              endColumnNumber: columnNumber - 1,
            };
            const functionName = currentCharacters.match(/^([a-zA-Z_]+)/)?.[1];
            if (!functionName) {
              throw new WittongueEditorMessage(
                `Invalid or missing function name: ${currentCharacters}`,
                linePosition,
                MarkerSeverity.Error
              );
            }
            const functionCallToken = new FunctionCallToken(
              functionName,
              nestedSequenceStack.count() + 1,
              linePosition
            );
            currentSequence.addToken(functionCallToken);
            nestedSequenceStack.push(functionCallToken.currentArgument);
            currentSequence = functionCallToken.currentArgument;
            nestedTokenStack.push(functionCallToken);
            currentCharacters = "";
            currentTokenType = null;
          } else if (ch === ")") {
            //close everything - nested token and nested sequence
            if (nestedTokenStack.peek() instanceof FunctionCallToken) {
              if (currentCharacters.length > 0) {
                currentSequence.addToken(createNonTextToken(currentCharacters));
              }
              const functionCall = nestedTokenStack.pop() as FunctionCallToken;
              functionCall.currentArgument.finishSequence(
                lineNumber,
                columnNumber
              );
              nestedSequenceStack.pop();
              currentSequence = nestedSequenceStack.peek();
              currentToken = null;
            }
          } else if (
            ch === "," &&
            nestedTokenStack.peek().type === TokenType.FUNCTION
          ) {
            //close the current argument sequence and start a new one
            const functionCall = nestedTokenStack.peek() as FunctionCallToken;
            if (nestedSequenceStack.count() <= 0) {
              throw new WittongueEditorMessage(
                `Function call ${functionCall.key} has no argument sequences, something went wrong.`,
                functionCall.linePosition,
                MarkerSeverity.Error
              );
            }

            if (currentCharacters.length > 0) {
              currentSequence.addToken(createNonTextToken(currentCharacters));
            }
            nestedSequenceStack.pop()!.finishSequence(lineNumber, columnNumber);

            nestedSequenceStack.push(functionCall.addArgument());

            functionCall.commasSeen++;
            currentSequence = functionCall.currentArgument;
          }
          //match control token
          else if (ch.match(/[?:~]/)) {
            contributeToCurrentCharactersOrGenerateToken(TokenType.CONTROL, ch);
          }
          //match whitespace but not in a text token.
          else if (ch.match(/\s/)) {
            if (currentToken && currentToken instanceof TextToken) {
              currentToken.addTextContent(ch);
              currentToken.linePosition.endColumnNumber++;
            }
            //no matter what type of token we are in, we need to end the current token and start a new one
            else if (currentCharacters.length > 0) {
              //we have some characters that need to be parsed into a token
              currentSequence.addToken(createNonTextToken(currentCharacters));
            }

            currentCharacters = "";
            currentTokenType = null;
          }
          //
          else {
            if (currentToken && currentToken instanceof TextToken) {
              if (ch.match(nonTextCharacters)) {
                //TODO add ability to escape the character
                throw new WittongueEditorMessage(
                  `Cannot have ${ch} inside of string. Either end the current string, put it in { } or put it on a new line`,
                  currentToken.linePosition,
                  MarkerSeverity.Error
                );
              }
              currentToken.addTextContent(ch);
              currentToken.linePosition.endColumnNumber++;
            } else {
              contributeToCurrentCharactersOrGenerateToken(null, ch);
            }
          }
        }
      }
      columnNumber++;
    }
    if (currentToken) {
      throw new WittongueEditorMessage(
        `We still have a token that hasn't been added to a sequence ${currentToken.key}`,
        currentToken.linePosition,
        MarkerSeverity.Error
      );
    }
    part.nestedSequences = nestedSequences;
    part.contentSequences = contentSequences;

    return part;
    /**
     * Check if the new type is the same as the current type. If it is, then we add the character to the current characters. If it isn't, then we create a new token and add it to the current sequence
     * @param newType
     * @param ch
     */
    function contributeToCurrentCharactersOrGenerateToken(
      newType: TokenType | null,
      ch: string
    ) {
      if (currentToken) {
        if (currentToken instanceof TextToken) {
          currentToken.addTextContent(ch);
          return;
        }

        throw new WittongueEditorMessage(
          `Interrupted token: ${currentToken.key}. Not natural finish`,
          currentToken.linePosition,
          MarkerSeverity.Error
        );
      }
      //first new character after a whitespace or null type token
      if (currentTokenType === null && currentCharacters.length === 0) {
        currentCharacters = ch;
        currentTokenType = newType;
      } else if (newType === currentTokenType) {
        currentCharacters += ch;
      } else {
        if (currentCharacters.length > 0) {
          currentSequence.addToken(createNonTextToken(currentCharacters));
        }
        currentCharacters = ch;
        currentTokenType = newType;
      }
    }
    function createNonTextToken(tokenString: string): Token {
      currentCharacters = "";
      if (tokenString === "var") {
        return new DeclareVariableToken({
          startLineNumber: lineNumber,
          startColumnNumber: columnNumber - tokenString.length - 1,
          endLineNumber: lineNumber,
          endColumnNumber: columnNumber - 1,
        });
      } else if (tokenString.match(/[><=^*/+-](?:=)?/)) {
        return new OperatorToken(tokenString, 1, {
          startLineNumber: lineNumber,
          startColumnNumber: columnNumber - tokenString.length - 1,
          endLineNumber: lineNumber,
          endColumnNumber: columnNumber - 1,
        });
      }
      //TODO separate out variables by '.'
      else if (tokenString.match(/^[a-zA-Z_.]+$/)) {
        return new VariableToken(tokenString, {
          startLineNumber: lineNumber,
          startColumnNumber: columnNumber - tokenString.length - 1,
          endLineNumber: lineNumber,
          endColumnNumber: columnNumber - 1,
        });
      } else if (tokenString.match(/\?|:|~/)) {
        return new ControlFlowToken(tokenString, {
          startLineNumber: lineNumber,
          startColumnNumber: columnNumber - tokenString.length - 1,
          endLineNumber: lineNumber,
          endColumnNumber: columnNumber - 1,
        });
      } else if (tokenString.match(/^[0-9.]+$/)) {
        if (tokenString.match(/^[0-9]+(?:\.[0-9]+)?$/)) {
          return new NumberToken(parseFloat(tokenString), {
            startLineNumber: lineNumber,
            startColumnNumber: columnNumber - tokenString.length - 1,
            endLineNumber: lineNumber,
            endColumnNumber: columnNumber - 1,
          });
        } else {
          throw new WittongueEditorMessage(
            `Invalid number: ${tokenString}`,
            {
              startLineNumber: lineNumber,
              startColumnNumber: columnNumber - tokenString.length - 1,
              endLineNumber: lineNumber,
              endColumnNumber: columnNumber - 1,
            },
            MarkerSeverity.Error
          );
        }
      } else {
        throw new WittongueEditorMessage(
          `Unrecognized token "${tokenString}". Variable names cannot contain numbers or special characters.`,
          {
            startLineNumber: lineNumber,
            startColumnNumber: columnNumber - tokenString.length - 1,
            endLineNumber: lineNumber,
            endColumnNumber: columnNumber - 1,
          },
          MarkerSeverity.Error
        );
      }
    }
  }
  compileFromRaw(input: string) {
    this.resetCompiler();
    this.parseIntoParts(input);
    const encounterParts = this.closedParts;
    const globalPart = this.parseRawContentIntoTokenSequences(this.globalLines);
    const outputs = encounterParts.map((part) => {
      return this.parseRawContentIntoTokenSequences(part);
    });
    // const json = JSON.stringify(outputs, null, 2);
    const compiledEncounter: CompiledEncounter = {
      title: "",
      description: "",
      startingPartKey: encounterParts[0].key,
      parts: outputs,
      globalLines: globalPart,
    };
    //if we made it all the way here
    const validator = new CompiledEncounterValidator(compiledEncounter);
    //run final checks

    return { compiledEncounter, warnings: this.warnings };
  }
}
export default WittongueCompiler;

import { Box, HStack, Text } from "@chakra-ui/react";
import { EncounterPartPreview } from "./EncounterPartPreview";
import { CollapsibleSection } from "ui/base-components";
import { CompiledEncounter } from "../wittongue/compiler";

interface Props {
  encounter: CompiledEncounter;
}
export default function CompiledEncounterPreview({ encounter }: Props) {
  return (
    <Box h="80vh" overflowY={"scroll"} overflowX={"hidden"} fontFamily={"edit"}>
      <Text>{`Starting Part: ${encounter.startingPartKey}`}</Text>
      <CollapsibleSection title={"Global Lines"}>
        <EncounterPartPreview part={encounter.globalLines} />
      </CollapsibleSection>
      {encounter.parts.map((part, index) => {
        return <EncounterPartPreview part={part} key={index} />;
      })}
    </Box>
  );
}

import { EncounterPart } from "@wittongue/compiler";
export class EncounterPartStack {
  stack: EncounterPart[] = [];
  constructor() {
    this.stack = [];
  }
  push(part: EncounterPart) {
    this.stack.push(part);
  }
  pop() {
    return this.stack.pop();
  }
  peek() {
    return this.stack[this.stack.length - 1];
  }
  first() {
    return this.stack[0];
  }
  count() {
    return this.stack.length;
  }
}

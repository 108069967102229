import { Box, HStack, Button, Text, ButtonGroup } from "@chakra-ui/react";
import { Link, Outlet } from "react-router-dom";

export default function HeaderLayout({}: {}) {
  return (
    <Box>
      <ButtonGroup
        bg="#14083899"
        pl={4}
        pr={4}
        pos={"sticky"}
        zIndex={"sticky"}
        top={0}
        w="100%"
        variant={"ghost"}
      >
        <Button as={Link} to={"/"} w="100%">
          Home
        </Button>
        <Button as={Link} to={"/trait-maker"} w="100%">
          Trait Maker
        </Button>
        <Button as={Link} to={"/encounter-editor"} w="100%">
          Encounter Editor
        </Button>
      </ButtonGroup>
      <Outlet />
    </Box>
  );
}

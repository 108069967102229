import {
  Box,
  Text,
  Button,
  HStack,
  Stack,
  SimpleGrid,
  Alert,
  useBoolean,
  Heading,
  Link,
  Tooltip,
} from "@chakra-ui/react";
import {
  Choice,
  ControlFlowToken,
  DivertToken,
  EncounterPart,
  FunctionCallToken,
  NumberToken,
  OperatorToken,
  SequenceReferenceToken,
  StageDirectionToken,
  TextToken,
  TokenSequence,
  VariableToken,
} from "@wittongue/compiler";
import { CollapsibleSection } from "ui/base-components";

const nestedBracketColors = [
  "purple.700",
  "green.700",
  "yellow.700",
  "orange.700",
  "pink.700",
  "teal.700",
  "blue.700",
  "cyan.700",
  "gray.700",
  "red.700",
];
export function EncounterPartPreview({ part }: { part: EncounterPart }) {
  function displayTokenSequences(sequences: TokenSequence[]) {
    const elements: JSX.Element[] = [];
    let index = 0;
    for (const sequence of sequences) {
      index++;
      elements.push(
        <Stack key={index}>
          <Text>{sequence.key}</Text>
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            overflowWrap={"break-word"}
            overflowX={"scroll"}
          >
            {displaySequenceContents(sequence)}
          </Box>
        </Stack>
      );
    }
    return elements;
  }

  function displaySequenceContents(content: TokenSequence) {
    const elements: JSX.Element[] = [];
    for (let i = 0; i < content.tokens.length; i++) {
      const currentToken = content.tokens[i];
      if (currentToken instanceof TextToken) {
        elements.push(
          <Text
            key={i}
            whiteSpace={"break-spaces"}
            flexDir={"row"}
            display={"flex"}
          >
            {currentToken.value}
          </Text>
        );
      } else if (currentToken instanceof NumberToken) {
        elements.push(
          <Text key={i} textDecor={"underline"}>
            {currentToken.value}
          </Text>
        );
      } else if (currentToken instanceof SequenceReferenceToken) {
        elements.push(
          <NestedSequencePreview key={i} sequenceKey={currentToken.key} />
        );
      } else if (currentToken instanceof VariableToken) {
        elements.push(
          <Text key={i} fontStyle={"italic"}>
            {currentToken.variableName}
          </Text>
        );
      } else if (currentToken instanceof ControlFlowToken) {
        elements.push(
          <Tooltip key={i} label={`If ${content.tokens[i - 1].key} is true...`}>
            <Text key={i} color={`#FDD782`} pl={1} pr={1}>
              {currentToken.key}
            </Text>
          </Tooltip>
        );
      } else if (currentToken instanceof FunctionCallToken) {
        elements.push(
          <FunctionCallPreview key={i} functionToken={currentToken} />
        );
      } else if (currentToken instanceof OperatorToken) {
        elements.push(
          <Tooltip
            key={i}
            label={`Operator with ${currentToken.precendence} priority`}
          >
            <Text key={i} textColor={"#5b8cfd"}>
              {currentToken.key}
            </Text>
          </Tooltip>
        );
      } else if (currentToken instanceof DivertToken) {
        elements.push(
          <HStack key={i}>
            <Text color={"blue.300"}>{currentToken.divertTo}</Text>
          </HStack>
        );
      } else if (currentToken instanceof StageDirectionToken) {
        elements.push(
          <HStack key={i}>
            <Text color={"#FDD782"}>{currentToken.key.toUpperCase()}</Text>
          </HStack>
        );
      }
    }
    return elements;
  }

  function createChoiceDisplays(choices: Choice[]) {
    const elements: JSX.Element[] = [];
    let index = 0;
    for (const choice of choices) {
      index++;
      elements.push(
        <Stack key={index}>
          <Button onClick={() => {}} variant={"outline"}>
            <Text color="blue.400">{choice.condition} </Text>
            <Text>{choice.divertTo}</Text>
          </Button>
          <Text overflowWrap={"anywhere"}>{choice.text}</Text>
        </Stack>
      );
    }
    return elements;
  }
  function FunctionCallPreview({
    functionToken,
  }: {
    functionToken: FunctionCallToken;
  }) {
    const argumentElements = functionToken.arguments.map((arg, index) => {
      return displaySequenceContents(arg);
    });
    return (
      <Stack>
        <Text color={`#5b8cfd`}>
          {`function `}
          {functionToken.functionName}
        </Text>
        {argumentElements.map((argumentElement, index) => {
          return (
            <HStack key={index}>
              <Text>{`arg${index + 1}: `}</Text>
              {argumentElement}
            </HStack>
          );
        })}
      </Stack>
    );
  }
  function NestedSequencePreview({ sequenceKey }: { sequenceKey: string }) {
    const [isOpen, { toggle }] = useBoolean();
    const sequence = part.nestedSequences.find(
      (sequence) => sequence.key === sequenceKey
    );
    if (!sequence) {
      return (
        <Alert status="error">{`Sequence ${sequenceKey} not found`}</Alert>
      );
    }
    const bracketColor =
      nestedBracketColors[sequence.depth % nestedBracketColors.length];
    return (
      <Box
        display={"inline-flex"}
        borderColor={bracketColor}
        borderBottomWidth={"4px"}
      >
        <Link onClick={toggle} color={bracketColor}>
          {isOpen ? `Collapse` : `{${sequenceKey}}`}
        </Link>
        {isOpen && (
          <>
            <Text color={bracketColor}>{`{`}</Text>
            {displaySequenceContents(sequence)}
            <Text color={bracketColor}>{`}`}</Text>
          </>
        )}
      </Box>
    );
  }

  return (
    <CollapsibleSection title={part.key}>
      <Box fontFamily={"edit"}>
        {part.contentSequences.length > 0 ? (
          displayTokenSequences(part.contentSequences)
        ) : (
          <Text textDecor={"underline"}>
            This part is empty. Maybe add some content?
          </Text>
        )}
      </Box>
      <Box>{part.choices.length > 0 && createChoiceDisplays(part.choices)}</Box>
      <Text bgColor="green">{part.defaultDivert}</Text>
      <CollapsibleSection title="Nested Sequences">
        {part.nestedSequences.map((sequence, index) => {
          return (
            <Box key={index}>
              <Text
                bgColor={
                  nestedBracketColors[
                    sequence.depth % nestedBracketColors.length
                  ]
                }
              >
                {sequence.key}
              </Text>
              {displaySequenceContents(sequence)}
            </Box>
          );
        })}
      </CollapsibleSection>
    </CollapsibleSection>
  );
}

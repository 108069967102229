import { Token, TokenType, TokenSequence } from ".";
export class SequenceReferenceToken extends Token {
  sequenceKey: string;
  constructor(sequence: TokenSequence) {
    super(
      `sequence_l${sequence.linePosition.startLineNumber}_c${sequence.linePosition.startColumnNumber}`,
      TokenType.SEQUENCE,
      sequence.linePosition
    );
    this.sequenceKey = sequence.key;
  }
}

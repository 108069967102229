import { Token, TokenType } from ".";
import { LinePosition } from "@wittongue/editor";

export class DivertToken extends Token {
  /**The key of the part to divert to */
  divertTo: string;
  constructor(key: string, divertTo: string, linePosition: LinePosition) {
    super(key, TokenType.DIVERT, linePosition);
    this.divertTo = divertTo;
    this.linePosition = linePosition;
  }
}

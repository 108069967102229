import { Token, TokenType } from ".";
import { LinePosition } from "@wittongue/editor";

export class NumberToken extends Token {
  value: number;
  constructor(value: number, linePosition: LinePosition) {
    super(`number_${value}`, TokenType.NUMBER, linePosition);
    this.value = value;
  }
}

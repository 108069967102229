import { TokenSequence } from "@wittongue/compiler";
/**
 * Helper class for dealing with nested Token Sequences
 */
export class SequenceStack {
  stack: TokenSequence[] = [];
  constructor() {
    this.stack = [];
  }
  push(sequence: TokenSequence) {
    this.stack.push(sequence);
  }
  pop() {
    return this.stack.pop();
  }
  peek() {
    return this.stack[this.stack.length - 1];
  }
  count() {
    return this.stack.length;
  }
}

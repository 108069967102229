export enum TokenTypes {
  CONTROL = "CONTROL",
  DIVERT = "DIVERT",
  FUNCTION = "FUNCTION",
  NUMBER = "NUMBER",
  OPERATOR = "OPERATOR",
  SEQUENCE = "SEQUENCE",
  STAGE_DIRECTION = "STAGE_DIRECTION",
  TEXT = "TEXT",
  NEW_VARIABLE = "NEW_VARIABLE",
  VARIABLE = "VARIABLE",
}

import { LinePosition } from "@wittongue/editor";

export class Choice {
  depth: number;
  mode: "repeating" | "once";
  text: string;
  condition: string | undefined;
  divertTo: string;
  linePosition: LinePosition;
  constructor(
    depth: number,
    mode: "repeating" | "once",
    text: string,

    divertTo: string,
    linePosition: LinePosition,
    condition?: string
  ) {
    this.depth = depth;
    this.mode = mode;
    this.text = text;
    this.condition = condition;
    this.divertTo = divertTo;
    this.linePosition = linePosition;
  }
}

import { Token } from "@wittongue/compiler";
export class TokenStack {
  stack: Token[] = [];
  constructor() {
    this.stack = [];
  }
  push(token: Token) {
    this.stack.push(token);
  }
  pop() {
    return this.stack.pop();
  }
  peek() {
    return this.stack[this.stack.length - 1] || undefined;
  }
  count() {
    return this.stack.length;
  }
}
